// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/useUserStore';

const routes = [
  // Public Routes (accessible to everyone)
  {
    path: '/',
    name: 'LandingPage',
    component: () => import('@/views/public/LandingPage.vue') // Lazy-loaded
  },
  {
    path: '/how-it-works',
    name: 'HowItWorks',
    component: () => import('@/views/public/HowItWorks.vue') // Lazy-loaded
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/public/Register.vue') // Lazy-loaded
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/public/Login.vue') // Lazy-loaded
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/public/ResetPassword.vue') // Lazy-loaded
  },
  {
    path: '/:pathMatch(.*)*', // Catch-all route for 404
    name: 'PageNotFound',
    component: () => import('@/views/PageNotFound.vue') // Lazy-loaded
  },
  // Authenticated Routes (protected routes )
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/protected/Home.vue'), // Lazy-loaded
    meta: { requiresAuth: true }
  },
  {
    path: '/leaderboard/:challengeId?',
    name: 'Leaderboard',
    component: () => import('@/views/protected/Leaderboard.vue'), // Lazy-loaded
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/new-challenge',
    name: 'NewChallenge',
    component: () => import('@/views/protected/NewChallenge.vue'), // Lazy-loaded
    meta: { requiresAuth: true }
  },
  {
    path: '/new-score',
    name: 'NewScore',
    component: () => import('@/views/protected/NewScore.vue'), // Lazy-loaded
    meta: { requiresAuth: true }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('@/views/protected/Feedback.vue'), // Lazy-loaded
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Navigation Guard
router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();

  // Wait for the user state to be resolved if still loading
  if (userStore.loading) {
    // Use a promise or watch function to wait for loading to complete
    await new Promise((resolve) => {
      const unwatch = userStore.$watch(
        () => userStore.loading,
        (loading) => {
          if (!loading) {
            unwatch(); // Stop watching when loading completes
            resolve();
          }
        }
      );
    });
  }

  const isAuthenticated = !!userStore.user;

  if (to.meta.requiresAuth && !isAuthenticated) {
    // Redirect to login if not authenticated
    next({ name: 'Login' });
  } else {
    // Proceed to the route
    next();
  }
});

export default router;
