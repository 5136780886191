<template>
  <nav class="navbar">
    <div class="navbar-container">
      <!-- Brand Logo -->
      <router-link to="/" class="brand-logo">{{ $t('overall.appName') }}</router-link>

      <!-- Hamburger Menu Button -->
      <button @click.stop="toggleMobileMenu" class="hamburger" aria-label="Toggle navigation"
        :aria-expanded="isMobileMenuOpen.toString()">
        <span class="hamburger-line"></span>
        <span class="hamburger-line"></span>
        <span class="hamburger-line"></span>
      </button>

      <!-- Navigation Links and Buttons -->
      <div ref="mobileMenu" :class="['navbar-links-container', { 'is-active': isMobileMenuOpen }]">
        <ul class="navbar-links">
          <li v-if="isAuthenticated">
            <router-link to="/home" @click="closeMobileMenu">{{ $t('overall.home') }}</router-link>
          </li>
          <li v-if="!isAuthenticated">
            <router-link to="/" @click="closeMobileMenu">{{ $t('overall.landing') }}</router-link>
          </li>
          <li v-if="!isAuthenticated">
            <router-link to="/how-it-works" @click="closeMobileMenu">{{ $t('overall.howItWorks1') }}</router-link>
          </li>
          <li v-if="isAuthenticated">
            <router-link to="/leaderboard" @click="closeMobileMenu">{{ $t('overall.leaderboard') }}</router-link>
          </li>
          <li v-if="isAuthenticated">
            <router-link to="/new-challenge" @click="closeMobileMenu">{{ $t('overall.newChallenge') }}</router-link>
          </li>
          <li v-if="isAuthenticated">
            <router-link to="/new-score" @click="closeMobileMenu">{{ $t('overall.newScore') }}</router-link>
          </li>
          <li v-if="isAuthenticated">
            <router-link class="feedback" to="/feedback" @click="closeMobileMenu">{{ $t('overall.feedback') }}</router-link>
          </li>
        </ul>
        <div class="navbar-buttons">
          <li v-if="!isAuthenticated">
            <router-link to="/login" @click="closeMobileMenu">
              <button class="nav-button">{{ $t('overall.login') }}</button>
            </router-link>
          </li>
          <li v-if="!isAuthenticated">
            <router-link to="/register" @click="closeMobileMenu">
              <button class="nav-button">{{ $t('overall.register') }}</button>
            </router-link>
          </li>
          <li v-if="isAuthenticated">
            <button class="nav-button" @click="handleLogout">{{ $t('overall.logout') }}</button>
          </li>
        </div>

<LoadingSpinner :isLoading="isLoading" />

        <!-- Language Switcher -->
        <div class="language-switcher">
          <img src="../assets/images/us.png" alt="English" @click="changeLanguage('en')" class="language-flag"
            :class="{ active: currentLanguage === 'en' }" />
          <img src="../assets/images/german.png" alt="German" @click="changeLanguage('de')" class="language-flag"
            :class="{ active: currentLanguage === 'de' }" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useUserStore } from '@/stores/useUserStore';
import { useLanguageStore } from '@/stores/languageStore';
import { useI18n } from 'vue-i18n';
import useAuth from '@/composables/useAuth';
import { useRouter } from 'vue-router';
import LoadingSpinner from './LoadingSpinner.vue';

const { logout } = useAuth();
const router = useRouter();
const isLoading = ref(false)

// Access the i18n instance for locale control
const { locale } = useI18n();

// Access the Pinia language store
const languageStore = useLanguageStore();
const currentLanguage = computed(() => languageStore.currentLanguage);

// Watch for changes in the store and update the locale
watch(currentLanguage, (newLang) => {
  locale.value = newLang;
});

// Function to change language and update the store
const changeLanguage = (lang) => {
  languageStore.setLanguage(lang);
  locale.value = lang; // Update locale immediately
};

// Reactive state for mobile menu visibility
const isMobileMenuOpen = ref(false);
const mobileMenu = ref(null);

// Access user authentication status
const userStore = useUserStore();
const isAuthenticated = computed(() => !!userStore.user);

// Function to toggle mobile menu visibility
function toggleMobileMenu() {
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
}

// Function to close mobile menu
function closeMobileMenu() {
  isMobileMenuOpen.value = false;
}

// Handle outside click to close menu
function handleOutsideClick(event) {
  if (isMobileMenuOpen.value && mobileMenu.value && !mobileMenu.value.contains(event.target)) {
    closeMobileMenu();
  }
}

// Add and remove event listeners
onMounted(() => {
  document.addEventListener('click', handleOutsideClick);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleOutsideClick);
});

const handleLogout = async () => {
  try {
    isLoading.value = true;
    await logout(router);
  } catch (error) {
    console.error('Error during logout:', error);
  }finally{
    isLoading.value = false;
  }
};
</script>



<style scoped>
/* Basic Navbar Styling */
.navbar {
  display: flex;
  justify-content: center;
  /* Center the navbar content horizontally */
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--color-border, #e6e6e6);
  /* Apple Silver */
  position: sticky;
  /* Makes the navbar stick at the top */
  top: 0;
  /* Sticks to the top of the viewport */
  z-index: 10;
  /* Ensures it stays above other elements */
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  /* Center the container content */
  justify-content: space-between;
  /* Distributes elements evenly */
  position: relative;
}

.brand-logo {
  text-decoration: none;
  color: var(--color-text-secondary, #e6e6e6);
  /* Apple Silver */
  font-size: 1.5rem;
  font-weight: 500;
  position: absolute;
  left: 0;
}

/* Language Switcher */
.language-switcher {
  display: flex;
  gap: 8px;
  margin-left: 16px;
}

.language-flag {
  width: 24px;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s;
}

.language-flag:hover {
  transform: scale(1.1);
}

.language-flag.active {
  border-bottom: 2px solid var(--color-accent-blue, #0066cc);
  /* True Blue */
}

/* Hamburger Button Styling */
.hamburger {
  display: block;
  background: none;
  border: none;
  color: var(--color-primary, #424245);
  /* Apple Gray */
  cursor: pointer;
  margin-left: auto;
}

.hamburger-line {
  display: block;
  width: 24px;
  height: 2px;
  margin: 4px 0;
  background-color: var(--color-primary, #424245);
  /* Apple Gray */
}

/* Navigation Links Container */
.navbar-links-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background-color: var(--color-secondary, #f5f5f7);
  /* Cultured White */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.15);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  padding: 16px;
  width: auto;
  max-width: 80vw;
}

/* Show menu when active */
.navbar-links-container.is-active {
  transform: translateX(0);
}

/* Links Styling */
.navbar-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-links li {
  margin: 8px 0;
}

.navbar-links a {
  text-decoration: none;
  color: var(--color-text-secondary, #424245);
  /* Apple Gray */
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: var(--color-accent-blue, #0066cc);
  /* True Blue */
}
.navbar-links a.feedback{
  color: var(--color-accent-orange);
}
.navbar-links a.feedback:hover{
  color: var(--color-accent-blue);
}


/* Navbar Buttons */
.navbar-buttons {
  display: flex;
  /* Changed from column to flex row */
  flex-wrap: wrap;
  gap: 8px;
  /* Adds spacing between buttons */
  margin-top: 16px;
}

.navbar-buttons li {
  list-style: none;
  width: 100%;
}

.nav-button {
  margin: 8px 0;
  padding: 8px 16px;
  background-color: var(--color-accent-blue, #0066cc);
  /* True Blue */
  border: none;
  border-radius: 4px;
  color: var(--color-text-secondary, #e6e6e6);
  /* Apple Silver */
  cursor: pointer;
  width: 100%;
  max-width: 240px;
  text-align: center;
  transition: background-color 0.3s ease;
  text-wrap: nowrap;
}

.nav-button:hover {
  background-color: var(--color-accent-orange, #f56300);
  /* Orange */
}

/* Media Query for Desktop Screens (Breakpoint at 1100px) */
@media (min-width: 1100px) {
  .hamburger {
    display: none;
  }

  .navbar-links-container {
    position: static;
    transform: translateX(0);
    background: none;
    box-shadow: none;
    flex-direction: row;
    align-items: center;
    width: auto;
  }

  .navbar-container {
    justify-content: flex-end;
    /* Pushes elements to the right */
  }

  .navbar-links {
    display: flex;
    margin-left: auto;
  }

  .navbar-links li {
    margin: 0 16px;
  }

  .navbar-buttons {
    display: flex;
    align-items: center;
    margin-top: 0;
    flex-wrap: nowrap;
    /* Ensures buttons stay in a single row */
  }

  .nav-button {
    margin-left: 8px;
    width: auto;
    max-width: none;
  }

  /* Mobile View Styling */
  @media (max-width: 1100px) {
    .language-switcher {
      display: flex;
      justify-content: center;
      /* Centers the flags horizontally */
      margin: 16px auto 0;
      /* Adds auto margins to center it within the container */
    }

    .navbar-links-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      /* Centers all children horizontally */
    }
  }
}
</style>
