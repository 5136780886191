import { defineStore } from 'pinia';

export const useLanguageStore = defineStore('languageStore', {
  state: () => ({
    currentLanguage: 'en', // Default language
  }),
  actions: {
    setLanguage(lang) {
      this.currentLanguage = lang;
    },
  },
});