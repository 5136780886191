import { defineStore } from 'pinia';
import useFirestore from '@/composables/useFirestore';
import { useUserStore } from '@/stores/useUserStore'; // Import user store for accessing user info

const { addFeedback, fetchFeedback, updateFeedbackInFirestore } = useFirestore();

export const useDevelopmentStore = defineStore('development', {
  state: () => ({
    feedback: [], // Array of all feedback entries
    lastFetched: null, // Timestamp for fetching new feedback
  }),

  actions: {
    // Fetch feedback from Firestore and map user votes
    async fetchFeedback(ignoreCache = false) {
      try {
        if (ignoreCache) this.lastFetched = null; // Ignore cached timestamp if forced
        const userStore = useUserStore();
        const userId = userStore.user?.uid;

        const newFeedback = await fetchFeedback(this.lastFetched);

        const formattedFeedback = newFeedback.map((feedback) => {
          const userVote = feedback.votes?.[userId] ?? ''; // Default to empty if no vote exists

          // Calculate urgencyScore as the sum of all numeric votes
          const urgencyScore = Object.values(feedback.votes || {}).reduce(
            (sum, vote) => sum + (parseInt(vote, 10) || 0),
            0
          );

          return {
            ...feedback,
            userVote,
            urgencyScore,
          };
        });

        const existingIds = new Set(this.feedback.map((f) => f.id));
        const uniqueFeedback = formattedFeedback.filter((f) => !existingIds.has(f.id));

        this.feedback.push(...uniqueFeedback);
        this.lastFetched = new Date().toISOString();
      } catch (error) {
        console.error('Error fetching feedback:', error);
      }
    },

    // Add new feedback
    async addFeedback(feedback) {
      try {
        const userStore = useUserStore();
        const userId = userStore.user?.uid;

        const newFeedback = {
          ...feedback,
          creator: userId,
          votes: {}, // Initialize empty votes
          urgencyScore: 0, // Initialize urgency score as a number
          createdAt: new Date().toISOString(),
          status: 'pending', // Default status
        };

        const addedFeedback = await addFeedback(newFeedback);

        // Format the added feedback and add it to the store
        const formattedFeedback = {
          ...addedFeedback,
          userVote: '', // Default vote for the current user
        };

        this.feedback.push({
          ...formattedFeedback,
          urgencyScore: Object.values(formattedFeedback.votes || {}).reduce(
            (sum, vote) => sum + (parseInt(vote, 10) || 0), // Recalculate urgencyScore
            0
          ),
        });
      } catch (error) {
        console.error('Error adding feedback:', error);
      }
    },

    // Update feedback votes and urgency score
    async updateFeedback(updatedFeedback) {
      try {
        const index = this.feedback.findIndex((f) => f.id === updatedFeedback.id);
    
        if (index !== -1) {
          // Recalculate urgencyScore as the sum of votes
          updatedFeedback.urgencyScore = Object.values(updatedFeedback.votes || {}).reduce(
            (sum, vote) => sum + (parseInt(vote, 10) || 0), // Ensure numeric votes
            0
          );
    
          // Update the feedback entry in the store
          this.feedback[index] = {
            ...this.feedback[index], // Retain existing fields
            ...updatedFeedback,     // Merge updated fields
          };
        } else {
          console.warn(`Feedback with ID ${updatedFeedback.id} not found in the store.`);
        }
    
        // Persist the updated feedback to Firestore
        await updateFeedbackInFirestore(updatedFeedback);
      } catch (error) {
        console.error('Error updating feedback:', error);
      }
    },

    // Clear all feedback and timestamps
    clearStore() {
      this.feedback = [];
      this.lastFetched = null;
    },

    // Initialize default votes for a user
    initializeUserVotes(userId) {
      this.feedback.forEach((f) => {
        if (!f.votes[userId]) {
          f.votes[userId] = ''; // Default to empty state
        }
      });
    },
  },
});
