// src/stores/useLeaderboardStore.js
import { defineStore } from 'pinia';
import useFirestore from '@/composables/useFirestore';

export const useLeaderboardStore = defineStore('leaderboard', {
  state: () => ({
    challenges: [],
    scores: [],
    greatestLeaderboardScores: [],
    loading: false,
    userProfiles: new Map(),
  }),
  actions: {
    async fetchInitialData() {
      const { getAllChallenges, getAllScores } = useFirestore();

      this.loading = true;
      try {
        this.challenges = await getAllChallenges();

        this.scores = await getAllScores();

        await this.fetchUserProfiles();
        this.calculateGreatestLeaderboard();
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      } finally {
        this.loading = false;
      }
    },

    async fetchUserProfiles() {
      const { getUserProfile } = useFirestore();
      const uniqueUserIds = [...new Set(this.scores.map((score) => score.userId))];
      for (const userId of uniqueUserIds) {
        if (!this.userProfiles.has(userId)) {
          const profile = await getUserProfile(userId);
          if (profile) {
            this.userProfiles.set(userId, profile.username || 'Unknown User');
          }
        }
      }
    },

    calculateGreatestLeaderboard() {
      const userScoresMap = new Map();

      for (const challenge of this.challenges) {
        const challengeScores = this.scores.filter((score) => score.challengeId === challenge.id);
        const bestScoresMap = new Map();

        for (const score of challengeScores) {
          if (!bestScoresMap.has(score.userId)) {
            bestScoresMap.set(score.userId, score);
          } else {
            const existingBestScore = bestScoresMap.get(score.userId);
            const isBetterScore =
              (challenge.optimizationGoal === 'Bigger is better' && score.score > existingBestScore.score) ||
              (challenge.optimizationGoal === 'Smaller is better' && score.score < existingBestScore.score);

            if (isBetterScore) {
              bestScoresMap.set(score.userId, score);
            }
          }
        }

        const bestScoresArray = Array.from(bestScoresMap.values()).sort((a, b) => {
          return challenge.optimizationGoal === 'Bigger is better' ? b.score - a.score : a.score - b.score;
        });

        const totalParticipants = bestScoresArray.length;
        for (let index = 0; index < totalParticipants; index++) {
          const score = bestScoresArray[index];
          const rank = index + 1;
          const points = totalParticipants - rank + 1;

          if (!userScoresMap.has(score.userId)) {
            userScoresMap.set(score.userId, { totalScore: 0, username: '' });
          }

          const userEntry = userScoresMap.get(score.userId);
          userEntry.totalScore += points;

          // Fetch username from userProfiles map
          if (!userEntry.username) {
            userEntry.username = this.userProfiles.get(score.userId) || `User ${score.userId}`;
          }

          userScoresMap.set(score.userId, userEntry);
        }
      }

      const sortedLeaderboard = Array.from(userScoresMap.entries())
        .map(([userId, data]) => ({
          userId,
          ...data,
        }))
        .sort((a, b) => b.totalScore - a.totalScore)
        .map((entry, index) => ({ ...entry, rank: index + 1 })); // Add rank based on position

      this.greatestLeaderboardScores = sortedLeaderboard;
    },

    // Get challenges a user has participated in
    getUserParticipatedChallenges(userId) {
      const userChallengeIds = new Set(
        this.scores
          .filter((score) => score.userId === userId)
          .map((score) => score.challengeId)
      );
      return this.challenges.filter((challenge) => userChallengeIds.has(challenge.id));
    },

    // Get scores by a specific challenge ID
    getScoresByChallenge(challengeId) {
      if (!challengeId) {
        return [];
      }

      const scoresForChallenge = this.scores.filter((score) => score.challengeId === challengeId);

      if (scoresForChallenge.length === 0) {
        console.warn(`No scores found for challengeId: ${challengeId}`);
      }

      return scoresForChallenge;
    },

    // Get best score for a user in a challenge
    getUserBestScoreInChallenge(challengeId, userId) {
      const scores = this.getScoresByChallenge(challengeId).filter((score) => score.userId === userId);
      const challenge = this.challenges.find((challenge) => challenge.id === challengeId);

      if (!scores.length || !challenge) return null;

      if (challenge.optimizationGoal === 'Bigger is better') {
        return Math.max(...scores.map((score) => score.score));
      } else {
        return Math.min(...scores.map((score) => score.score));
      }
    },

    getUserRankInChallenge(challengeId, userId) {
      const scores = this.getScoresByChallenge(challengeId); // All scores for the challenge
      const challenge = this.challenges.find((challenge) => challenge.id === challengeId);

      if (!challenge) return null;

      // Create a map to store the best score for each user
      const userBestScores = new Map();

      for (const score of scores) {
        const existingBestScore = userBestScores.get(score.userId);
        if (
          !existingBestScore || // No score logged for this user yet
          (challenge.optimizationGoal === 'Bigger is better' && score.score > existingBestScore) ||
          (challenge.optimizationGoal === 'Smaller is better' && score.score < existingBestScore)
        ) {
          userBestScores.set(score.userId, score.score); // Update with the better score
        }
      }

      // Convert map to an array of { userId, score } objects for sorting
      const sortedUsers = Array.from(userBestScores.entries())
        .map(([userId, score]) => ({ userId, score }))
        .sort((a, b) =>
          challenge.optimizationGoal === 'Bigger is better' ? b.score - a.score : a.score - b.score
        );

      // Find the rank of the specified user
      const userIndex = sortedUsers.findIndex((entry) => entry.userId === userId);
      return userIndex >= 0 ? userIndex + 1 : 'N/A';
    },

    getFilteredScores(challengeId, showBestScoresOnly) {
      // Retrieve scores for the specified challenge
      const scoresForChallenge = this.getScoresByChallenge(challengeId);
    
      if (showBestScoresOnly) {
        const challenge = this.challenges.find((c) => c.id === challengeId);
        if (!challenge) {
          console.warn(`Challenge not found for challengeId: ${challengeId}`);
          return [];
        }
    
        // Map to track the best score per user
        const bestScoresMap = new Map();
    
        // Iterate through scores to identify the best score for each user
        scoresForChallenge.forEach((score) => {
          const existingBestScore = bestScoresMap.get(score.userId);
    
          // **Updated Comparison Logic**
          if (
            !existingBestScore || // If no score exists for this user yet
            (challenge.optimizationGoal === 'Bigger is better' && score.score > existingBestScore.score) || // Replace if better
            (challenge.optimizationGoal === 'Smaller is better' && score.score < existingBestScore.score)   // Replace if smaller
          ) {
            bestScoresMap.set(score.userId, score); // **Ensure correct replacement**
          }
        });
    
        // Convert map to an array and sort it
        const bestScores = Array.from(bestScoresMap.values()).sort((a, b) => {
          return challenge.optimizationGoal === 'Bigger is better' ? b.score - a.score : a.score - b.score;
        });
    
        return bestScores;
      }
    
      // If showing all scores, sort according to the optimization goal
      const challenge = this.challenges.find((c) => c.id === challengeId);
      if (!challenge) {
        console.warn(`Challenge not found for challengeId: ${challengeId}`);
        return [];
      }
    
      const allScores = scoresForChallenge.sort((a, b) => {
        return challenge.optimizationGoal === 'Bigger is better' ? b.score - a.score : a.score - b.score;
      });
    
      return allScores;
    }

  },
});
