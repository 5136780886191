import { ref } from 'vue';
import { } from 'firebase/auth';
import { auth } from '@/firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, setPersistence, browserLocalPersistence, sendPasswordResetEmail } from 'firebase/auth';
import useFirestore from './useFirestore';
import { useLeaderboardStore } from '@/stores/useLeaderboardStore';
import { useUserStore } from '@/stores/useUserStore';

const user = ref(null);

// Encapsulate store usage in a function to avoid Pinia initialization issues
const getStores = () => {
  const userStore = useUserStore();
  const leaderboardStore = useLeaderboardStore();
  return { userStore, leaderboardStore };
};

// Set persistence for session
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error('Error setting persistence:', error);
});

// Track user state
onAuthStateChanged(auth, async (currentUser) => {
  if (currentUser) {
    user.value = currentUser;
    const { userStore, leaderboardStore } = getStores();
    await userStore.fetchUserData(currentUser.uid); // Load user data into store
    await leaderboardStore.fetchInitialData(); // Load leaderboard data
  } else {
    user.value = null;
    const { userStore } = getStores();
    userStore.clearUser(); // Clear user data on logout
  }
});

const useAuth = () => {
  const { createUserProfile } = useFirestore();

  const register = async (email, password, username, router) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      user.value = userCredential.user;

      const { userStore, leaderboardStore } = getStores();

      await createUserProfile(user.value.uid, { username, email }); // Ensure profile is created
      await userStore.fetchUserData(user.value.uid); // Fetch user data immediately
      await leaderboardStore.fetchInitialData();

      router.push({ name: 'Home' }); // Redirect to Home after registration
      return userCredential;
    } catch (error) {
      throw error;
    }
  };

  const login = async (email, password, router) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      user.value = userCredential.user;

      const { userStore, leaderboardStore } = getStores();

      await userStore.fetchUserData(user.value.uid); // Fetch user data immediately
      await leaderboardStore.fetchInitialData();

      router.push({ name: 'Home' }); // Redirect to Home after login
      return userCredential;
    } catch (error) {
      throw error;
    }
  };

  const logout = async (router) => {
    try {
      await signOut(auth);
      user.value = null;

      const { userStore } = getStores();
      userStore.clearUser(); // Clear local user data

      router.push({ name: 'LandingPage' }); // Redirect to Landing Page after logout
    } catch (error) {
      throw error;
    }
  };

  const resetPassword = async (email, router) => {

    try {
      await sendPasswordResetEmail(auth, email);
      router.push('/login'); // Redirect to login page after success
    } catch (error) {
      // Ensure proper error structure with a fallback message
      throw new Error(error?.message || 'Failed to send password reset email. Please try again.');
    }
  };

  return { user, register, login, logout, resetPassword };
};

export default useAuth;
