// src/composables/useFirestore.js
import { db } from '@/firebaseConfig';
import {
  collection,
  addDoc,
  doc,
  setDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
  getDoc,
  orderBy,
  updateDoc,
} from 'firebase/firestore';
import { getLastFetchedTimestamp, setLastFetchedTimestamp } from '@/utils/timestampManager';
import { useUserStore } from '@/stores/useUserStore';

const useFirestore = () => {
  const createUserProfile = async (uid, profileData) => {
    try {
      const userRef = doc(db, 'users', uid);
      await setDoc(userRef, { ...profileData }, { merge: true });
    } catch (error) {
      console.error('Error creating/updating user profile:', error);
      throw error;
    }
  };

  const getUserProfile = async (uid) => {
    try {
      const userRef = doc(db, 'users', uid);
      const userSnap = await getDoc(userRef);
      return userSnap.exists() ? userSnap.data() : null;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error;
    }
  };

  const addChallenge = async (challengeData) => {
    try {
      const challengesRef = collection(db, 'challenges');
      const docRef = await addDoc(challengesRef, {
        ...challengeData,
        participantCount: 0,
        timestamp: serverTimestamp(),
      });
      return { id: docRef.id, ...challengeData };
    } catch (error) {
      console.error('Error adding challenge:', error);
      throw error;
    }
  };

  const addScore = async (scoreData) => {
    try {
      const userStore = useUserStore(); // Access the user store
      const userId = userStore.user?.uid;

      if (!userId) {
        throw new Error('User ID is undefined. Ensure the user is logged in.');
      }

      const username = userStore.user?.username || 'Unknown User';
      const scoresRef = collection(db, 'scores');

      const denormalizedScoreData = {
        ...scoreData,
        userId,
        username,
        timestamp: serverTimestamp(),
      };

      // Add the score to the scores collection
      await addDoc(scoresRef, denormalizedScoreData);

      // Update the challenge's participantCount and participants array
      const challengeRef = doc(db, 'challenges', scoreData.challengeId);
      const challengeSnap = await getDoc(challengeRef);

      if (challengeSnap.exists()) {
        const currentData = challengeSnap.data();
        const participants = currentData.participants || [];
        const isNewParticipant = !participants.includes(userId);

        if (isNewParticipant) {
          const updatedCount = (currentData.participantCount || 0) + 1; // Increment participant count
          await updateDoc(challengeRef, {
            participantCount: updatedCount, // Update participantCount in Firestore
            participants: [...participants, userId], // Add the userId to the participants array
          });
          console.log(`Participant count updated to: ${updatedCount}`);
        }
      } else {
        console.error(`Challenge with ID ${scoreData.challengeId} not found.`);
      }
    } catch (error) {
      console.error('Error adding score:', error);
      throw error;
    }
  };

  const getAllChallenges = async () => {
    try {
      const challengesRef = collection(db, 'challenges');
      const querySnapshot = await getDocs(query(challengesRef, orderBy('timestamp')));
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error('Error fetching challenges:', error);
      return [];
    }
  };

  const getAllScores = async () => {
    try {
      const scoresRef = collection(db, 'scores');
      const querySnapshot = await getDocs(query(scoresRef, orderBy('timestamp')));
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error('Error fetching scores:', error);
      return [];
    }
  };

  const addFeedback = async (feedback) => {
    try {
      const feedbackRef = collection(db, 'feedback');
      const docRef = await addDoc(feedbackRef, {
        ...feedback,
        createdAt: serverTimestamp(),
        votes: feedback.votes || {},
        urgencyScore: 0,
      });

      const docSnap = await getDoc(docRef);
      return { id: docRef.id, ...docSnap.data() };
    } catch (error) {
      console.error('Error adding feedback:', error);
      throw error;
    }
  };

  const fetchFeedback = async (lastFetched = null) => {
    try {
      const feedbackRef = collection(db, 'feedback');
      let q = query(feedbackRef, orderBy('createdAt', 'desc'));

      if (lastFetched) {
        q = query(feedbackRef, where('createdAt', '>', new Date(lastFetched)), orderBy('createdAt', 'desc'));
      }

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        urgencyScore: Object.values(doc.data().votes || {}).reduce(
          (sum, vote) => sum + (parseInt(vote, 10) || 0),
          0
        ),
      }));
    } catch (error) {
      console.error('Error fetching feedback:', error);
      throw error;
    }
  };

  const updateFeedbackInFirestore = async (updatedFeedback) => {
    try {
      const feedbackRef = doc(db, 'feedback', updatedFeedback.id);
      await updateDoc(feedbackRef, {
        ...updatedFeedback,
        urgencyScore: Object.values(updatedFeedback.votes || {}).reduce(
          (sum, vote) => sum + (parseInt(vote, 10) || 0),
          0
        ),
      });
    } catch (error) {
      console.error('Error updating feedback in Firestore:', error);
      throw error;
    }
  };

  return {
    createUserProfile,
    getUserProfile,
    addChallenge,
    addScore,
    getAllChallenges,
    getAllScores,
    addFeedback,
    fetchFeedback,
    updateFeedbackInFirestore,
  };
};

export default useFirestore;
