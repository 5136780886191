// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import i18n from './locales/i18n'; // Updated import path
import './assets/styles/variables.css';
import './assets/styles/general.css';

const app = createApp(App);

app.use(router);
app.use(createPinia());
app.use(i18n); // Use i18n

app.mount('#app');
