import { defineStore } from 'pinia';
import { ref } from 'vue';
import { auth } from '@/firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import useFirestore from '@/composables/useFirestore';
import { useDevelopmentStore } from '@/stores/useDevelopmentStore'; // Add other stores as needed

export const useUserStore = defineStore('user', () => {
  const user = ref(null);
  const lastUserId = ref(null); // Track the last logged-in user's ID
  const loading = ref(true);

  const { getUserProfile } = useFirestore();

  const setUser = (userData) => {
    user.value = userData;
    lastUserId.value = userData?.uid || null; // Update the last known user ID
    loading.value = false;
  };

  const clearUser = () => {
    user.value = null;
    lastUserId.value = null; // Clear the last known user ID
    loading.value = false;
  };

  const fetchUserData = async (uid) => {
    try {
      const userData = await getUserProfile(uid);
      if (userData) {
        setUser({ ...userData, uid }); // Ensure UID is included
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // Clear all relevant stores
  const clearAllStores = () => {
    const developmentStore = useDevelopmentStore();
    developmentStore.clearStore();
    // Add calls to clear other stores as needed
  };

  // Re-fetch all data for the newly logged-in user
  const fetchAllData = async () => {
    const developmentStore = useDevelopmentStore();
    await developmentStore.fetchFeedback(true); // Fetch fresh data for feedback
    // Add calls to fetch other data as needed
  };

  // Monitor auth state changes
  onAuthStateChanged(auth, async (currentUser) => {
    if (currentUser) {
      if (lastUserId.value !== currentUser.uid) {
        // If the user ID has changed, clear stores and fetch fresh data
        clearAllStores();
        setUser({
          uid: currentUser.uid,
          email: currentUser.email,
        });
        await fetchUserData(currentUser.uid);
        await fetchAllData();
      } else {
        // If the same user, just update the state
        setUser({
          uid: currentUser.uid,
          email: currentUser.email,
        });
        await fetchUserData(currentUser.uid);
      }
    } else {
      clearUser();
      clearAllStores();
    }
  });

  const logout = async () => {
    try {
      await signOut(auth);
      clearUser();
      clearAllStores();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return { user, setUser, clearUser, fetchUserData, logout };
});
